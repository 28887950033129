import {render, html, svg} from 'lighterhtml';
import {whenDefined} from "wicked-elements";
const LighterHTML = {
    html() { return render(this.element, html.apply(null, arguments)); },
    svg() { return render(this.element, svg.apply(null, arguments)); }
};

export default {
    ...LighterHTML,


    state: {
        prev: "2020",
        current: "2022",
        next: "2023"

    },
    init(){
        this.element.state = this.state;
        whenDefined(".k_slider").then(() => {

            this.element.slider = document.querySelector(".k_slider").mySwiper
            this.element.togglePrev = this.togglePrev();
            this.element.toggleNext = this.toggleNext();

        } )
        this.element.updateYears = this.updateYears.bind(this);
        this.element.next = this.next.bind(this);
        this.element.prev = this.prev.bind(this);
        this.render();
    },
    updateYears(prev = "", current = "", next = ""){
        let state = this.element.state;
        state = {
            prev: prev !== state.prev ? prev : state.prev,
            current: current !== state.current ? current : state.current,
            next: next !== state.next ? next: state.next,
        }
        this.element.state = state;
    },
    next(){
       console.log("NEXT SLIDE");
        this.element.querySelectorAll(".o_date").forEach((date) => date.classList.add("date-transition"));
        render(this.element.querySelector("#date-right-placeholder"), html`${this.element.state.next}`);
        this.element.querySelector("#date-left").classList.replace("c_left","c_left-out");
        this.element.querySelector("#date-center").classList.replace("c_center","c_left");
        this.element.querySelector("#date-right").classList.replace("c_right","c_center");
        this.element.querySelector("#date-right-placeholder").classList.replace("c_right-out","c_right");

        setTimeout(() => {
            this.render();
        }, 300)
    },
    prev(){
        this.element.querySelectorAll(".o_date").forEach((date) => date.classList.add("date-transition"));
        render(this.element.querySelector("#date-left-placeholder"), html`${this.element.state.prev}`);
        this.element.querySelector("#date-left").classList.replace("c_left","c_center");
        this.element.querySelector("#date-center").classList.replace("c_center","c_right");
        this.element.querySelector("#date-right").classList.replace("c_right","c_right-out");
        this.element.querySelector("#date-left-placeholder").classList.replace("c_left-out","c_left");

        setTimeout(() => {
            this.render();
        }, 300)
    },
    toggleNext(){
        return () => this.element.slider.slideNext();
    },
    togglePrev(){
        return () => this.element.slider.slidePrev();
    },


    render(){
        let prev = this.element.state.prev;
        let current = this.element.state.current;
        let next = this.element.state.next;
        console.log(this);
        console.log(this.element.slider);

        let classes =  {
            leftOut: "c_left-out",
            left: "c_left",
            center: "c_center",
            right: "c_right",
            rightOut: "c_right-out"
        };
        console.log(classes);
        let dates = html`
         <div class="o_horizontal-wrapper c_timeline">
                    <span id="date-left-placeholder" class="o_date ${classes.leftOut}"></span>
                   <span id="date-left" class="o_date ${classes.left}" onclick="${this.element.togglePrev}">${prev}</span>
                    <span id="date-center" class="o_date ${classes.center}">${current}</span>
                    <span id="date-right" class="o_date ${classes.right}" onclick="${this.element.toggleNext}">${next}</span>
                    <span id="date-right-placeholder" class="o_date ${classes.rightOut}"></span>

                </div>
        `;
        this.html`
        <style>
        ${`
        .o_date{
        font-family: neue-haas-grotesk-display, sans-serif;
        box-sizing: border-box;
        position: static;
        display: block;
        width: auto;
        margin-top: 0px;
        transform: none;
        color: #000;
        font-size: 96px;
        line-height: 96px;
        font-weight: 500;
        }
        .date-transition{
          transition: all 0.3s;

        }
        .c_left{
          position:absolute;
          left:0;
          cursor: pointer;
        }
        .c_center{
          position:absolute;
          left:50%;
          transform: translateX(-50%);
        }
        .c_right{
          position: absolute;
          right:0%;
          cursor: pointer;
        }
        .c_right-out{
          position: absolute;
          right:-100%;
        }
         .c_left-out{
          position: absolute;
          left:-100%;
        }
        
        
        `}
        </style>
        <div class="o_section">
               ${dates}
        </div>
        
        `
    },

}