import {whenDefined} from "wicked-elements";
import {render, html, svg} from 'lighterhtml';
const LighterHTML = {
    html() { return render(this.element, html.apply(null, arguments)); },
    svg() { return render(this.element, svg.apply(null, arguments)); }
};

export default {
    ...LighterHTML,
    observedAttributes: ["direction"],
    attributeChanged(name, oldValue, newValue){
      if(name === "direction" && (oldValue === "left" || newValue === "left")) {
          whenDefined(".k_slider").then(() => {
              this.element.slider = document.querySelector(".k_slider").mySwiper;

                  this.element.onclick  = () => {
                      this.element.slider.slidePrev();
                  };

      })

      }
        if(name === "direction" && (oldValue === "right" || newValue === "right")) {

            whenDefined(".k_slider").then(() => {
                this.element.slider = document.querySelector(".k_slider").mySwiper;

                this.element.onclick = () => {
                    this.element.slider.slideNext();
                }

            })}
        this.render();
    },
    init(){
        this.element.state = {};
        this.element.state.active = true;
        this.element.state.arrow = this.element.getAttribute("arrow");
    },


    onMouseEnter(){
        let cursor = document.querySelector(".k_cursor");
        cursor.setAttribute("direction",this.element.getAttribute("direction"));
        cursor.setAttribute("active","true");
        this.element.state.active = false;
        this.render();
    },

    onMouseLeave(){
        let cursor = document.querySelector(".k_cursor");
        cursor.setAttribute("active","false");
        this.element.state.active = true;
        this.render();
    },

    render(){
        this.html`
        <img style="display: ${this.element.state.active ? "block" : "none"}" src=${this.element.state.arrow} alt="">
        `
    }

}