export default {
    observedAttributes: ['date', 'slide-type'],
    init() {
    this.element.state = {};
    this.element.openSlide = this.openSlide;
    this.element.closeSlide = this.closeSlide;
    },
    openSlide() {
        document.querySelector(".k_timeline").classList.add("hidden");

        this.element.querySelector(".o_photo-link").classList.add("active");
        this.element.classList.add("expand");
        setTimeout(() => {
            this.element.querySelector(".o_data-wrapper").classList.add("active");
            this.element.setAttribute("slide-type","active");
        }, 300);
},
    closeSlide() {
        document.querySelector(".k_timeline").classList.remove("hidden");

        this.element.querySelector(".o_data-wrapper").classList.remove("active");
        this.element.classList.remove("expand");


        this.element.querySelector(".o_photo-link").classList.remove("active");
        this.element.setAttribute("slide-type","inactive");
    },
    attributeChanged(name, oldValue, newValue) {
        if (name === "slide-type" && newValue === "inactive") {
            this.element.querySelector(".o_photo-link").onclick = () => {
               this.openSlide();
            }
        }
        if (name === "slide-type" && newValue === "active") {
            this.element.querySelector(".o_photo-link").onclick = () => {
            this.closeSlide();


            }
        }
    }


}