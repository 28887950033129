import {render} from 'lighterhtml';
export default {
    init(){
        this.element.backpagesCounter = 0;
     this.element.link = this.element.querySelector("a");
        this.element.setAttribute("active","false");
        this.element.adress = this.element.link.href;
        this.element.path = this.element.link.pathname;
     this.element.overlay = this.element.querySelector(".o_page-overlay");
     this.element.open = this.open.bind(this);
     this.element.close = this.close.bind(this);
     this.fetchData(this.element.adress).then( data => {
         this.element.content = data.content;
         this.element.title = data.title;
         this.render();
     }
     );
    },
    observedAttributes: ["active"],
    attributeChanged(name,oldValue,newValue){
      if(name === "active"){
          if(newValue === "true"){
              this.element.overlay.classList.add("active");
              this.element.link.onclick = this.element.close;
          }
          if(newValue === "false"){
              this.element.overlay.classList.remove("active");
              this.element.link.onclick = this.element.open;
          }
      }
    },

    async fetchData(location){
        let content = await fetch(location);
        let response = await content.text();
        let parser = new DOMParser();
        let doc = parser.parseFromString( response, 'text/html');
        return {content : doc.querySelector("#content"), title: doc.title};
    },
    open(event){
        if (event) event.preventDefault();
        let pages = document.querySelectorAll(".k_page-link");
        pages.forEach((page) => {
            if(page.getAttribute("active") === "true") {
                this.element.backpagesCounter -= 1;
                page.setAttribute("active", "false");
            }
        })
        this.element.setAttribute("active","true");

        history.pushState({active: true, path:this.element.path},null,this.element.path);
        this.element.backpagesCounter -= 1;

        window.onpopstate = () => {

            this.element.setAttribute("active",false);
            if (this.element.backpagesCounter !== 0) {
                if (this.element.backpagesCounter < -1) history.go(this.element.backpagesCounter + 1);
                this.element.backpagesCounter = 0;
             }
             window.onpopstate = null;
         };
    },
    close(event){
        if (event) event.preventDefault();
        this.element.setAttribute("active","false");
        window.onpopstate = null;

        if(this.element.backpagesCounter !== 0) {
            history.go(this.element.backpagesCounter);
            this.element.backpagesCounter = 0;
        }
    },
    render(){
        render(this.element.overlay, this.element.content);
        this.element.link.onclick = this.element.open;
    }
}