import {render, html, svg} from 'lighterhtml';
const LighterHTML = {
    html() { return render(this.element, html.apply(null, arguments)); },
    svg() { return render(this.element, svg.apply(null, arguments)); }
};

export default {
    ...LighterHTML,
    state: {
        visible: false,
        direction: "left",
        left: "left",
        right: "right",
        xPosition: 0,
        yPosition: 0,
        listenerActive: false,
    },
    shape: {
        left: svg`
<svg width="96" height="96" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 1)" stroke="#000" fill="none" fill-rule="evenodd"><circle cx="47" cy="47" r="47"/><path d="M63.326 47H31.663M35.621 42.547L31.168 47l4.453 4.453"/></g></svg>
    `,
        right: svg`
<svg width="96" height="96" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 1)" stroke="#000" fill="none" fill-rule="evenodd"><circle cx="47" cy="47" r="47"/><path d="M32.663 47.989h31.663M59.368 43.537l4.948 4.452-4.948 4.453"/></g></svg>
    `,
        replay: svg`
<svg width="96" height="96" xmlns="http://www.w3.org/2000/svg"><g stroke="#000" fill="none" fill-rule="evenodd"><circle cx="48" cy="48" r="47.625"/><path d="M48 62.625c-8.1 0-14.625-6.525-14.625-14.625S39.9 33.375 48 33.375 62.625 39.9 62.625 48"/><path d="M57 43.5l5.775 4.65 4.65-5.775"/></g></svg>
        `
    } ,
    observedAttributes: ['active','direction','left','right'],
    attributeChanged(name, oldValue, newValue){
      if (name ==="direction") this.element.state.direction = newValue;
      if (name === "active") this.updateListener(newValue);
      if (name === "left" && oldValue !== newValue) this.element.state.left = newValue;
      if (name === "right" && oldValue !== newValue) this.element.state.right = newValue;
      this.render();
    },
    init(){
        this.element.state = this.state;
        this.element.shape = this.shape;
    },

    updateListener(active) {
        if (!this.element.state.listenerActive && active === "true") {
            this.addListener();
        }

        if (this.element.state.listenerActive && active === "false") {
            this.removeListener();
        }
    },

    getTooltipPosition(e) {
        this.element.state.xPosition = e.clientX;
        this.element.state.yPosition = e.clientY;
        this.render();
    },
    addListener() {
        this.getTooltipPosition = this.getTooltipPosition.bind(this);
        window.addEventListener('mousemove', this.getTooltipPosition);
        this.element.state.visible = true;
        this.element.state.listenerActive = true;
    },

    removeListener() {
        this.getTooltipPosition = this.getTooltipPosition.bind(this);
        window.removeEventListener('mousemove', this.getTooltipPosition);
        this.element.state.visible = false;
        this.element.state.listenerActive = false;
    },

    render(){
        this.html`
    <style>
            ${`
            @keyframes fadein {
            from {opacity: 0;}
            to {opacity: 1;}
          }
          
            .k_cursor {
            display: ${this.element.state.visible ? 'block' : 'none'};
            position: fixed;
            top: 0px;
            left: 0px;
            pointer-events: none;
            animation-name: fadein;
            animation-duration: 0.4s;
            z-index: 999;
            transform: translateY(${this.element.state.yPosition - 48}px) translateX(${this.element.state.xPosition - 48 }px);
            }
        
    `}
        </style>
    <div>
    ${this.element.shape[this.element.state[this.element.state.direction]]}
    </div>
    `
    }



}