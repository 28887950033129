import {whenDefined} from "wicked-elements";

export default {
    init() {
    this.element.slider = {};
    this.element.sliderElement = {};

        this.element.slider.slideTo = () => console.log("Slider not yet initialized");
    whenDefined(".k_slider").then(() => {
        this.element.sliderElement = document.querySelector(".k_slider");
        this.element.slider = this.element.sliderElement.mySwiper;
    });
    },
    onClick(event) {
    event.preventDefault();

         whenDefined(".k_page-link").then(() => {
             let pageCount = 0;
             let pages = document.querySelectorAll(".k_page-link");
             pages.forEach((page) => {
                 if (page.getAttribute("active") === "true") {
                     pageCount += 1;
                     page.close();
                 }
             })
             if (pageCount === 0) this.element.slider.slideTo(parseInt(this.element.sliderElement.getAttribute("initialSlideIndex")));


         });
}
}