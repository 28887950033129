export default {
    init(){
    let action = this.element.getAttribute("menu-action");
    if (action === "open") this.element.onclick = this.open;
    if (action === "close") this.element.onclick = this.close;
    },
    open(){
      let menuWrapper = document.querySelector(".o_nav-wrapper");
      menuWrapper.classList.add("active");
    },
    close(){
        let menuWrapper = document.querySelector(".o_nav-wrapper");
        menuWrapper.classList.remove("active");
    }
}