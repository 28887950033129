import Swiper from 'swiper';
import './slider-styles.css';
import {whenDefined} from "wicked-elements";

export default {
init(){
    console.log("IM UP");
    let dates = document.querySelectorAll('.o_date');
    [].forEach.call(dates, function(date) {
        date.parentElement.removeChild(date);
    });
    let firstSlide = document.querySelector(".swiper-slide:first-child");
    let lastSlide = document.querySelector(".swiper-slide:last-child");
    firstSlide.querySelector(".k_slide").setAttribute("special","first");
    lastSlide.querySelector(".k_slide").setAttribute("special","last");
    this.element.mySwiper = new Swiper(document.querySelector('#slider-container'), {
        init: false,
        // Optional parameters

        keyboard: {
            enabled: true,
            onlyInViewport: false,
        },
        loop: true,
        wrapperClass: "swiper-wrapper",
        initialSlide: parseInt(this.element.getAttribute("initialSlideIndex")),
        slideClass: "swiper-slide",
        direction: "horizontal",
        slidesPerView: 'auto',
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        slidesPerGroup: 1,


    })
    this.element.mySwiper.initialSlide = parseInt(this.element.getAttribute("initialSlideIndex"));
    this.element.style.display = "block";
    this.element.mySwiper.getSlideIndex = this.getSlideIndex;
    this.element.mySwiper.hashRead = false;
    this.element.mySwiper.on("init",function(){
        let firstSlide = undefined;
        if(location.hash.length > 1) firstSlide = location.hash.substring(1);
        this.hashRead = true;
        if (firstSlide) this.slideTo(this.getSlideIndex(this.slides,firstSlide));

    });
    this.element.mySwiper.on("slideChange",function() {
        console.log("SLIDE CHANGE");
        if (Boolean(this.activeSlide)) {
            let slideBefore = this.activeSlide.querySelector(".k_slide");
            console.log("ACTIVE SLIDE FROM BEFORE");
            console.log(slideBefore);
            if (slideBefore.getAttribute("slide-type") === "active") {
                slideBefore.closeSlide();
                console.log("SLIDE BEFORE CLOSED")
            }
        }

        this.activeSlide = this.slides[this.activeIndex];
        let specialAttribute = this.activeSlide.querySelector(".k_slide").getAttribute("special");
        let prevSlide = specialAttribute === "first" ? undefined : this.slides[this.activeIndex-1];
        let nextSlide = specialAttribute === "last" ? undefined : this.slides[this.activeIndex+1];
        if (this.hashRead) history.replaceState('','',"#"+ this.activeSlide.querySelector(".k_slide").getAttribute("date"));
        let cursor = document.querySelector(".k_cursor");
        let left = specialAttribute === "first" ? "replay" : "left";
        let right = specialAttribute === "last" ? "replay" : "right";
        whenDefined(".k_cursor").then(() => {
            cursor.setAttribute("left", left);
            cursor.setAttribute("right", right);
        });


        let timeline = document.querySelector(".k_timeline");
        let isTimelineHidden = timeline.classList.contains("hidden");
        let isThisSlideActive = this.activeSlide.querySelector(".k_slide").getAttribute("slide-type") === "active";
        if (isThisSlideActive){
            if (!isTimelineHidden) timeline.classList.add("hidden");
        }
        else{
            if (isTimelineHidden) timeline.classList.remove("hidden");
        }
        let prevYear = prevSlide ? prevSlide.querySelector(".k_slide").getAttribute("date") : undefined;
        let currentYear = this.activeSlide.querySelector(".k_slide").getAttribute("date");
        let nextYear = nextSlide ? nextSlide.querySelector(".k_slide").getAttribute("date") : undefined;

        whenDefined(".k_timeline").then(() => {
            timeline.updateYears(prevYear,currentYear,nextYear);
        });
    } );
    this.element.mySwiper.on('slideNextTransitionStart', function(){
        let timeline = document.querySelector(".k_timeline");
        whenDefined(".k_timeline").then(() => {
            timeline.next();
        });
    });
    this.element.mySwiper.on('slidePrevTransitionStart', function(){
        let timeline = document.querySelector(".k_timeline");
        whenDefined(".k_timeline").then(() => {
            timeline.prev();
        });
    });
    this.element.mySwiper.init();
},

getSlideIndex(slides,date){
    return slides.findIndex((slide) => {
        let component = slide.querySelector(".k_slide")
        return component.getAttribute("date") === date;
    });

}

}