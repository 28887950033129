import {define} from 'wicked-elements';

import Slider from '/src/Slider';
import Timeline from '/src/Timeline'
import Slide from '/src/Slide';
import Arrow from '/src/Arrow';
import Cursor from '/src/Cursor';
import Logo from '/src/Logo';
import Page from '/src/Page';
import fallback from '/src/fallback';
import Menu from '/src/Menu';


let mql = window.matchMedia('(min-width: 992px)');



if(mql.matches){
    define('.k_slide', Slide);
    define('.k_slider', Slider);
    define('.k_slider-nav', Arrow);
    define('.k_cursor', Cursor);
    define(".k_timeline",Timeline);
    define(".k_logo",Logo);
    define(".k_page-link", Page);

}
else{
    define(".k_menu",Menu);
    window.addEventListener("load", fallback);
}
if(mql.addEventListener){
mql.addEventListener("change", function() {
    console.log("BREAKPOINT HIT!")
    window.location.reload(false);
})
}
else{
    mql.addListener(function() {
        console.log("BREAKPOINT HIT!")
        window.location.reload(false);
    })
}










